import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,2,4,5];

export const dictionary = {
		"/": [~6],
		"/api/auth": [32],
		"/changelog": [33],
		"/(app)/(authorized)/collections": [~7,[2],[3]],
		"/(app)/(authorized)/collections/[collectionId]": [~8,[2],[3]],
		"/(app)/faqs": [~21,[2],[3]],
		"/(app)/help": [22,[2],[3]],
		"/(app)/locations/[country=country]": [~24,[2],[3]],
		"/(app)/locations/[slug]": [~25,[2],[3]],
		"/(app)/locations/[...rest]": [~23,[2],[3]],
		"/(app)/(authorized)/plan": [~9,[2],[3]],
		"/(app)/pricing": [~26,[2],[3]],
		"/(app)/(authorized)/profile": [10,[2],[3]],
		"/(app)/(authorized)/profile/billing": [~11,[2],[3]],
		"/(app)/(authorized)/profile/billing/invoices/[id]": [~12,[2],[3]],
		"/(app)/(authorized)/profile/subscriptions": [13,[2],[3]],
		"/(app)/(authorized)/projects": [~14,[2],[3]],
		"/(app)/(authorized)/projects/[projectId]": [15,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/budgets": [~16,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/budgets/[budgetId]": [~17,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/chats": [~18,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/chats/[service_company_id]": [~19,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/locations": [20,[2,4],[3]],
		"/(auth)/reset-password": [29,[5]],
		"/(auth)/signin": [30,[5]],
		"/(app)/signup": [~27,[2],[3]],
		"/(auth)/signup/error": [31,[5]],
		"/(app)/signup/success": [~28,[2],[3]],
		"/widgets": [~34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';